import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography
} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useEffect, useState} from "react";
import {KeyValuePair} from "../../types/CommonTypes";
import {FilterSettings} from "../../types/FilterTypes";

interface FilterProps {
  filters: KeyValuePair;
  filtersSettings: FilterSettings[];
  onFiltersSubmit: (newFilters: any) => void;
}

const Filters = (props: FilterProps) => {
  const [tempFilters, setTempFilters] = useState<KeyValuePair>({} as KeyValuePair);

  const setFilterValue = (event: any) => {
    const {name, value} = event.target;

    if (name === 'type' && value === 0) return;

    setTempFilters((prevState: any) => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const setDateFilterValue = (event: any, name: string) => {
    if (!event) {
      setTempFilters((prevState: any) => {
        const copy = {...prevState};

        delete copy[name];

        return copy;
      });
    } else {
      setTempFilters((prevState: any) => {
        return {
          ...prevState,
          [name]: event
        }
      });
    }
  }

  const onFilterSubmit = () => {
    if (Object.values(tempFilters).every((filter) => filter === '')) {
      props.onFiltersSubmit({});
    } else {
      props.onFiltersSubmit(tempFilters);
    }
  }

  const onFilterClear = () => {
    props.onFiltersSubmit({});
    setTempFilters({} as KeyValuePair);
  }

  return (
    <Toolbar sx={{paddingLeft: '0px !important', paddingRight: '0px !important', marginTop: '2rem'}}>
      <Card sx={{width: '100%'}}>
        <Accordion>
          <AccordionSummary>
            <Typography>Filtre</Typography>
            {Object.values(props.filters)?.length > 0 &&
                <Typography sx={{ml: 5}} color='primary'>Exista filtre aplicate</Typography>}
          </AccordionSummary>
          <AccordionDetails sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
            {props.filtersSettings.map((filterSettings: FilterSettings) => (
              <>
                {
                  filterSettings.type === 'date' &&
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box>
                            <DatePicker
                                label={`${filterSettings.display}`}
                                openTo="day"
                                views={['year', 'month', 'day']}
                                value={tempFilters[filterSettings.key as keyof KeyValuePair] || null}
                                onChange={(newValue) => {
                                  setDateFilterValue(newValue, `${filterSettings.key}`)
                                }}
                                renderInput={(params) => <TextField size='small' name={filterSettings.key}
                                                                    sx={{margin: '1rem'}} {...params} />}
                            />
                        </Box>
                    </LocalizationProvider>
                }
                {
                  filterSettings.type === 'text' &&

                    <TextField sx={{margin: '1rem'}}
                               size='small'
                               key={`${filterSettings.key}`}
                               label={filterSettings.display}
                               name={filterSettings.key}
                               value={tempFilters[filterSettings.key as keyof KeyValuePair] || ""}
                               type={filterSettings.type}
                               onInput={setFilterValue}
                    />
                }
                {
                  filterSettings.type === 'number' &&

                    <TextField sx={{margin: '1rem'}}
                               size='small'
                               key={`${filterSettings.key}`}
                               label={filterSettings.display}
                               name={filterSettings.key}
                               value={tempFilters[filterSettings.key as keyof KeyValuePair] || ""}

                               onInput={setFilterValue}
                    />
                }
                {
                  filterSettings.type === 'dropdown' &&

                    <FormControl variant='filled' sx={{width: '200px', margin: '1rem'}}>
                        <InputLabel id="dropdown-filter-label">{filterSettings.display}</InputLabel>
                        <Select
                            labelId="dropdown-filter-label"
                            value={tempFilters[filterSettings.key as keyof KeyValuePair] || 0}
                            name={filterSettings.key}
                            onChange={setFilterValue}
                        >
                          {filterSettings?.dropdownValues?.map((item: { label: string, value: string }) => {
                            return <MenuItem value={item.value}>{item.label?.toUpperCase()}</MenuItem>
                          })
                          }
                        </Select>
                    </FormControl>
                }

              </>
            ))}
            <Button onClick={onFilterSubmit}>Submit</Button>
            <Button onClick={onFilterClear}>Sterge toate filtrele</Button>
          </AccordionDetails>
        </Accordion>
      </Card>
    </Toolbar>
  )
}

export default Filters;