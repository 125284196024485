import {Employee} from "../../types/EmployeeDto";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Box, Button, Card, Container, Toolbar, useMediaQuery, useTheme} from "@mui/material";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {FieldValues, useForm} from "react-hook-form";
import BasicFormControl from "../../components/form-helpers/BasicFormControl";
import AutocompleteFormControl from "../../components/form-helpers/AutocompleteFormControl";
import DatePickerFormControl from "../../components/form-helpers/DatePickerFormControl";

const EmployeeDetailsPage = () => {
  const [employee, setEmployee] = useState<any>({} as any);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [partners, setPartners] = useState<any>([]);

  const {id} = useParams();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      partner: '',
      first_name: '',
      last_name: '',
      nationality: '',
      social_security_number: '',
      ssn_series: '',
      ssn_number: '',
      phone: '',
      email: '',
      town: '',
      address: '',
      ssn_provider: '',
      ssn_start_date: '',
      ssn_end_date: ''
    }
  });

  useEffect(() => {
    api.get(`/employees/${id}`).then((res: Employee) => {
      setEmployee(res);

      reset({
        first_name: res.first_name || "",
        last_name: res.last_name || "",
        nationality: res.nationality || "",
        social_security_number: res.social_security_number || "",
        ssn_series: res.ssn_series || "",
        ssn_number: res.ssn_number.toString() || "",
        phone: res.phone || "",
        email: res.email || "",
        town: res.town || "",
        address: res.address || "",
        ssn_provider: res.ssn_provider || "",
        ssn_start_date: res.ssn_start_date || "",
        ssn_end_date: res.ssn_end_date || "",
        partner: res.partner_id?.toString() || ""
      })
    }).catch((err: any) => {
      toast.error(err);
    })
  }, []);

  const onPartnerAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/autocomplete?searchKey=${value}`).then((res) => {
        setPartners(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }

  const onPartnerAutocompleteChange = (event: any, value: any) => {
    setValue('partner', value.partner_id);
  }

  const toggleEdit = () => {
    setEditMode(!editMode);
  }

  const onEmployeeDataSubmit = (data: FieldValues) => {
    data.employee_id = employee.employee_id;

    api.put('/employees', data).then((res) => {
      toast.success('Employee successfully updated');
    }).catch((err) => {
      toast.error(err.message);
    })
  }

  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <Toolbar sx={{width: '100%', paddingLeft: '0px !important', paddingRight: '0px !important'}}>
        <Card sx={{width: '100%'}}>
          <Button onClick={toggleEdit}>Editare</Button>
        </Card>
      </Toolbar>
      <Box>
        <form onSubmit={handleSubmit(onEmployeeDataSubmit)}>
          <Box sx={{display: "flex", flexDirection: "column", width: '100%'}}>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>

              <BasicFormControl control={control} name='first_name' displayText='First name'
                                disabled={!editMode}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'First name is required'
                                  }
                                }} errors={errors}/>

              <BasicFormControl control={control} name='last_name' displayText='Last name'
                                disabled={!editMode}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'Last name is required'
                                  }
                                }} errors={errors}/>
              {employee?.partner &&
                  <AutocompleteFormControl control={control} name='partner' displayText='Employer'
                                           disabled={!editMode}
                                           options={partners}
                                           onInputHandler={onPartnerAutocompleteInput}
                                           onChangeHandler={onPartnerAutocompleteChange}
                                           optionLabelFunc={(partners: any) => partners.partner_name}
                                           defaultValue={{
                                             partner_id: employee?.partner?.partner_id,
                                             partner_name: employee?.partner?.name
                                           } || ""}/>}
            </Box>

            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>

              <BasicFormControl control={control} name='nationality' displayText='Nationality' disabled={!editMode}/>

              <BasicFormControl control={control} name='town' displayText='Town' disabled={!editMode}/>

              <BasicFormControl control={control} name='address' displayText='Address' disabled={!editMode}/>

            </Box>

            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>

              <BasicFormControl control={control} name='phone' displayText='Phone' disabled={!editMode}/>

              <BasicFormControl control={control} name='email' displayText='Email' disabled={!editMode}/>

            </Box>

            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
              <BasicFormControl control={control} name={'social_security_number'} displayText={'Social security number'}
                                rules={{
                                  minLength: {
                                    value: 13,
                                    message: 'Social security number must have a length of 13 characters!'
                                  },
                                  maxLength: {
                                    value: 13,
                                    message: 'Social security number must have a length of 13 characters!'
                                  }
                                }} errors={errors} disabled={!editMode}/>

              <BasicFormControl control={control} name='ssn_series' displayText='Series'
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'ID Series is required'
                                  }
                                }} errors={errors} disabled={!editMode}/>

              <BasicFormControl control={control} name='ssn_number' displayText='Number' type='number'
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'ID Number is required'
                                  }
                                }} errors={errors} disabled={!editMode}/>
            </Box>

            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>

              <BasicFormControl control={control} rules={{required: {value: true, message: 'ID Provider is required'}}}
                                name='ssn_provider' displayText='Provider' errors={errors} disabled={!editMode}/>

              <DatePickerFormControl control={control} name='ssn_start_date' displayText='Start date'
                                     rules={{required: {value: true, message: 'ID Start date is required'}}}
                                     errors={errors} disabled={!editMode}/>

              <DatePickerFormControl control={control} name='ssn_end_date' displayText='Expiry date'
                                     rules={{required: {value: true, message: 'ID Expiry date is required'}}}
                                     errors={errors} disabled={!editMode}/>

            </Box>
          </Box>
          {editMode &&
              <Button sx={{my: 2, width: '100%'}} type="submit" variant="contained">Submit employee data</Button>
          }
        </form>
      </Box>
    </Container>
  )
}

export default EmployeeDetailsPage;