import HomePage from "../pages/home/HomePage";

// other
import {FC} from "react";
import PartnerPage from "../pages/partners/PartnersPage";
import {
  AdminPanelSettings,
  BadgeSharp,
  Business,
  CarRental,
  Home,
  Inventory,
  PointOfSale,
  Receipt
} from "@mui/icons-material";
import ProductsPage from "../pages/products/ProductsPage";
import EmployeesPage from "../pages/employee/EmployeesPage";
import TimesheetDetailsPage from "../pages/timesheet/TimesheetDetailsPage";
import IssuedInvoicesPage from "../pages/invoices/IssuedInvoicesPage";
import ReceivedInvoicesPage from "../pages/invoices/ReceivedInvoicesPage";
import NoticesPage from "../pages/invoices/NoticesPage";
import AutoFleetPage from "../pages/auto-fleet/AutoFleetPage";
import CreateTimesheetEntriesPage from '../pages/timesheet/CreateTimesheetEntriesPage';
import BankRegisterPage from "../pages/bank-register/BankRegisterPage";
import CashRegisterPage from "../pages/cash-register/CashRegisterPage";
import ReceiptPage from "../pages/receipt/ReceiptPage";
import OrdersPage from "../pages/orders/OrdersPage";
import UsersPage from "../pages/users/UsersPage";
import {Roles} from "../common/enums/roles";

// interface
export interface RouteDefinition {
  key: string;
  title: string;
  path: string;
  enabled: boolean;
  component?: FC<{}>;
  icon?: any;
  nested?: any[];
  roles?: Roles[];
}

export const routes: Array<RouteDefinition> = [
  {
    key: 'home-route',
    title: 'Home',
    path: '/',
    enabled: true,
    component: HomePage,
    icon: Home,
  },
  {
    key: 'commercial-route',
    title: 'Commercial',
    path: '/commercial',
    enabled: true,
    icon: Business,
    nested: [
      {
        key: 'partners-route',
        title: 'Parteneri',
        path: '/partners',
        enabled: true,
        component: PartnerPage,
      }
    ],
    roles: [Roles.Administrator, Roles.Operator]
  },
  {
    key: 'nomenclator-routes',
    title: 'Nomenclator',
    path: '/nomenclator',
    enabled: true,
    icon: Inventory,
    nested: [
      {
        key: 'products-route',
        title: 'Produse',
        path: '/products',
        enabled: true,
        component: ProductsPage,
      }
    ],
    roles: [Roles.Administrator, Roles.Operator]
  },
  {
    key: 'invoices-route',
    title: 'Financiar',
    path: '/invoices',
    enabled: true,
    icon: Receipt,
    nested: [
      {
        key: "issued-invoices",
        title: "Facturi emise",
        path: "/invoices/issued",
        enabled: true,
        component: IssuedInvoicesPage,
        roles: [Roles.Administrator, Roles.Operator]
      },
      {
        key: "received-invoices",
        title: "Facturi furnizor",
        path: "/invoices/received",
        enabled: true,
        component: ReceivedInvoicesPage,
        roles: [Roles.Administrator, Roles.Operator]
      },
      {
        key: "notices",
        title: "Avize",
        path: "/invoices/notice",
        enabled: true,
        component: NoticesPage,
        roles: [Roles.Administrator, Roles.Operator, Roles.Sofer]
      }
    ],
  },
  {
    key: 'treasury-route',
    title: 'Trezorerie',
    path: '/treasury',
    enabled: true,
    icon: PointOfSale,
    nested: [
      {
        key: 'cash-register-route',
        title: 'Registru casa',
        path: '/cashregister',
        enabled: true,
        component: CashRegisterPage
      },
      {
        key: 'bank-register-route',
        title: 'Registru banca',
        path: '/bankregister',
        enabled: true,
        component: BankRegisterPage
      },
      {
        key: 'receipts',
        title: 'Chitante',
        path: '/receipts',
        enabled: true,
        component: ReceiptPage
      }
    ],
    roles: [Roles.Administrator, Roles.Operator]
  },
  {
    key: "hr-route",
    title: "HR",
    path: "/hr",
    enabled: true,
    icon: BadgeSharp,
    nested: [
      {
        key: 'timesheet-route',
        title: 'Pontaj',
        path: '/hr/timesheet',
        enabled: true,
        component: TimesheetDetailsPage
      },
      {
        key: 'employees-route',
        title: 'Angajati',
        path: '/hr/employees',
        enabled: true,
        component: EmployeesPage
      },
      {
        key: 'timesheet-add-route',
        title: 'Adauga pontaj',
        path: '/timesheet/add',
        enabled: true,
        component: CreateTimesheetEntriesPage,
      }
    ],
    roles: [Roles.Administrator, Roles.Operator]
  },
  {
    key: "tms-route",
    title: "TMS",
    path: "/tms",
    enabled: true,
    icon: CarRental,
    nested: [
      {
        key: "auto-fleet-route",
        title: "Flota auto",
        path: "/autofleet",
        enabled: true,
        component: AutoFleetPage
      },
      {
        key: "orders-route",
        title: "Comenzi",
        path: "/orders",
        enabled: true,
        component: OrdersPage
      }
    ],
    roles: [Roles.Administrator, Roles.Operator]
  },
  {
    key: "admin-route",
    title: "ADMIN",
    path: "/admin",
    enabled: true,
    icon: AdminPanelSettings,
    nested: [
      {
        key: "auto-fleet-route",
        title: "Utilizatori",
        path: "/users",
        enabled: true,
        component: UsersPage,
      }
    ],
    roles: [Roles.Administrator]

  }
];
