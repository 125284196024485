import {
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination,
  TableRow,
  Typography
} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import {Add} from "@mui/icons-material";

interface TableProps {
  headers: any;
  rows: any;
  actions?: any[],
  handleAddActionClick?: () => void;
  handleExportToPdf?: (row: any) => void;
  getFormattedCell?: (row: any, value: string, rowKey: string) => any;
  getCellColor?: (rowKey: string, index: number) => string;
}

const Table = (props: TableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [headers, setHeaders] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentActionRow, setCurrentActionRow] = useState<any>({});
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    setHeaders([...props.headers,
      {
        id: 'actions',
        width: 100,
        label: 'Operatii',
        type: 'actions'
      },
      {
        id: 'add',
        width: 100,
        label: <Button onClick={handleAddActionClick}>
          <Add/>
        </Button>,
        type: 'add'
      }
    ])
  }, [props.headers]);

  const handleActionsMenuClick = (event: any, row: any) => {
    setIsActionsMenuOpen(true);
    setCurrentActionRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleActionsMenuClose = () => {
    setIsActionsMenuOpen(false);
    setAnchorEl(null);
    setCurrentActionRow(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddActionClick = () => {
    return props.handleAddActionClick ? props.handleAddActionClick() : () => {
    };
  }

  const getFormattedCell = (row: any, value: string, rowKey: string) => {
    return props.getFormattedCell!(row, value, rowKey);
  }

  const getCellColor = (rowKey: string, index: number) => {
    return props.getCellColor?.(rowKey, index);
  }

  return (
    <Box sx={{width: '100%', maxHeight: '100%', marginTop: '2rem', marginBottom: '2rem'}}>

      <Paper sx={{width: '100%', overflow: 'auto'}}>
        <TableContainer sx={{maxHeight: '600px', width: 'auto', overflow: 'auto'}}>
          <MuiTable stickyHeader aria-label="sticky table">
            <TableHead sx={{pading: 0}}>
              <TableRow>
                <>
                  {headers.map((header: any) => {
                    return (
                      <TableCell
                        sx={{fontSize: '0.6rem'}}
                        key={`${header.id}`}
                        align={header.align}
                        // style={{minWidth: header.width}}
                      >
                        {header.label}
                      </TableCell>
                    )
                  })}
                </>
              </TableRow>
            </TableHead>
            <TableBody>

              {props.rows && props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  return (
                    <TableRow sx={{width:'auto', backgroundColor: index % 2 !== 0 ? '#f7f7f7' : 'white'}}
                              key={`row-${index}`}
                              tabIndex={-1}>

                      {headers.map((header: any) => {
                        const value = header.composite ? header.id.map((compositeHeaderItem: string) => row[compositeHeaderItem]).join(' ') : row[header.id];
                        return (
                          <Fragment key={`${header.id}-${index}`}>
                            {
                              (typeof value === 'undefined' || value === null) &&
                              header.type !== 'add' && header.type !== 'actions' &&
                                <TableCell sx={{fontSize: '0.6rem', width:'auto'}}>
                                    -
                                </TableCell>
                            }
                            {
                              typeof value === 'boolean' &&
                                <TableCell sx={{fontSize: '0.6rem', width:'auto'}} key={`${header.id}-bool-body-row`}
                                           align={header.align}>
                                  {value ? 'Da' : 'Nu'}
                                </TableCell>
                            }
                            {
                              header && typeof value === 'string' && header.type !== 'date' &&
                                <TableCell sx={{fontSize: '0.6rem', width:'auto'}} key={`${header.id}-string-body-row`}
                                           align={header.align}>
                                  {header.type === 'href' ? getFormattedCell(row, value, header.id) : value || '-'}
                                </TableCell>
                            }
                            {
                              header && typeof value === 'number' &&
                                <TableCell sx={{fontSize: '0.6rem', width:'auto'}} key={`${header.id}-number-body-row`}
                                           align={header.align}>
                                    <>
                                      {value.toFixed(2)}
                                    </>
                                </TableCell>
                            }
                            {
                              header && header.type === 'date' && value &&
                                <TableCell sx={{
                                  width:'auto',
                                  fontSize: '0.6rem',
                                  color: header.coloredCell && getCellColor(header.id, index)
                                }}
                                           key={`${header.id}-date-body-row`} align={header.align}>
                                  {value ? new Date(value).toLocaleDateString() : '-'}
                                </TableCell>
                            }
                            {
                              header && header.type === 'array' && <>{value?.length > 0 ?
                                <TableCell
                                  sx={{fontSize: '0.6rem', width:'auto'}}
                                  key={`${header.id}-array-field`} align={header.align}>
                                  {value && value.length > 0 && value.map((item: any, index: number) => (
                                    <Typography sx={{fontSize: '0.6rem'}}
                                                key={`${index}-${value}`}
                                                variant='caption'>{item[header.arrayDisplayField] + ","}</Typography>
                                  ))}
                                </TableCell>
                                : <TableCell>-</TableCell>}
                                </>}
                            {
                              header && header.type === 'actions' &&
                                <TableCell key={`${header.id}-action-body-row`} align={header.align}>
                                  {props.actions && props.actions.length > 0 &&
                                      <>
                                          <Button
                                              id="basic-button"
                                              aria-controls={isActionsMenuOpen ? 'basic-menu' : undefined}
                                              aria-haspopup="true"
                                              aria-expanded={isActionsMenuOpen ? 'true' : undefined}
                                              onClick={(event) => handleActionsMenuClick(event, row)}
                                          >
                                              ...
                                          </Button>
                                          <Menu
                                              id="basic-menu"
                                              anchorEl={anchorEl}
                                              open={isActionsMenuOpen}
                                              onClose={handleActionsMenuClose}
                                              MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                              }}
                                          >
                                            {
                                              <Box>
                                                {props.actions.map((action, index) => {
                                                  return <MenuItem key={`${action.name}-${index}`}
                                                                   onClick={() => {
                                                                     setIsActionsMenuOpen(false);
                                                                     action.action(currentActionRow)
                                                                   }}>
                                                    {action.displayName}
                                                  </MenuItem>
                                                })
                                                }
                                              </Box>
                                            }
                                            {/*<MenuItem onClick={handleExportToPdfAction}>Export to PDF</MenuItem>*/}
                                          </Menu>
                                      </>
                                  }
                                  {!props.actions &&
                                      <Typography>-</Typography>
                                  }
                                </TableCell>
                            }
                            {
                              header && header.type === 'add' &&
                                <TableCell key={`${header.id}-add-body-row`} align={header.align}>

                                </TableCell>
                            }
                          </Fragment>
                        );
                      })
                      }
                    </TableRow>
                  );
                })}
            </TableBody>
          </MuiTable>
        </TableContainer>
        <TablePagination
                rowsPerPageOptions={[25, 50, 75, 100]}
                component="div"
                count={props?.rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
      </Paper>
      {/* <DataGrid rows={partners} columns={headers} getRowId={(row) => row.partner_id} /> */
      }
    </Box>
  )
}

export default Table;