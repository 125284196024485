import {Box, Button} from "@mui/material";
import {FieldValues, useForm} from "react-hook-form";
import {useState} from "react";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {CreateEmployee} from "../../types/EmployeeDto";
import BasicFormControl from "../form-helpers/BasicFormControl";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import DatePickerFormControl from "../form-helpers/DatePickerFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";

interface Props {
  onEmployeeInput: (invoice: CreateEmployee) => void;
}

const CreateEmployeeForm = (props: Props) => {
  const [partners, setPartners] = useState<any>([]);

  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      partner: '',
      first_name: '',
      last_name: '',
      nationality: '',
      social_security_number: '',
      ssn_series: '',
      ssn_number: '',
      phone: '',
      email: '',
      town: '',
      address: '',
      ssn_provider: '',
      ssn_start_date: '',
      ssn_end_date: '',
      partner_id: ''
    }
  });

  const onPartnerAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/user/autocomplete?searchKey=${value}`).then((res) => {
        setPartners(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }

  const onPartnerAutocompleteChange = (event: any, value: any) => {
    setValue('partner', value.partner_id);
    setValue('partner_id', value.partner_id);
  }

  const onEmployeeDataSubmit = (data: FieldValues) => {
    props.onEmployeeInput(data as CreateEmployee);
  }

  return (
    <form onSubmit={handleSubmit(onEmployeeDataSubmit)}>
      <Box sx={{display: "flex", flexDirection: "column", width: '100%'}}>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>

          <BasicFormControl control={control} name='last_name' displayText='Nume'
                            variant='outlined'
                            rules={{
                              required: {
                                value: true,
                                message: 'Numele este obligatoriu'
                              }
                            }} errors={errors}/>

          <BasicFormControl control={control} name='first_name' displayText='Prenume'
                            variant='outlined'
                            rules={{
                              required: {
                                value: true,
                                message: 'Prenumele este obligatoriu'
                              }
                            }} errors={errors}/>

          <AutocompleteFormControl control={control} name='partner' displayText='Angajator'
                                   rules={{
                                     required: {value: true, message:'Angajatorul este obligatoriu!'}
                                   }}
                                   variant='outlined'
                                   errors={errors}
                                   options={partners}
                                   onInputHandler={onPartnerAutocompleteInput}
                                   onChangeHandler={onPartnerAutocompleteChange}
                                   optionLabelFunc={(partners: any) => partners?.partner_name || ""}/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>

          <BasicFormControl control={control} name='town' displayText='Oras' variant='outlined'/>

          <BasicFormControl control={control} name='address' displayText='Adresa' variant='outlined'/>

        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>

          <BasicFormControl control={control} name='phone' displayText='Telefon' variant='outlined'/>

          <BasicFormControl control={control} name='email' displayText='Email' variant='outlined'/>

        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control} name={'social_security_number'} displayText='CNP'
                            rules={{
                              required: {
                                value: true,
                                message: 'CNP-ul este obligatoriu'
                              },
                              minLength: {
                                value: 13,
                                message: 'CNP-ul trebuie sa aiba o lungime de 13 caractere!'
                              },
                              maxLength: {
                                value: 13,
                                message: 'CNP-ul trebuie sa aiba o lungime de 13 caractere!'
                              }
                            }} errors={errors} variant='outlined'/>

          <BasicFormControl control={control} name='ssn_series' displayText='Serie CI'
                            rules={{
                              required: {
                                value: true,
                                message: 'Seria CI este obligatorie.'
                              }
                            }} errors={errors} variant='outlined'/>

          <BasicFormControl control={control} name='ssn_number' displayText='Number' type='number'
                            rules={{
                              required: {
                                value: true,
                                message: 'Numar CI este obligatoriu'
                              }
                            }} errors={errors} variant='outlined'/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>

          <BasicFormControl control={control} rules={{required: {value: true, message: 'Campul este obligatoriu.'}}}
                            name='ssn_provider' displayText='Emisa de' errors={errors} variant='outlined'/>

          <DatePickerFormControl control={control} name='ssn_start_date' displayText='Data emitere CI'
                                 rules={{required: {value: true, message: 'Data emitere CI este obligatorie'}}}
                                 errors={errors} variant='outlined'/>

          <DatePickerFormControl control={control} name='ssn_end_date' displayText='Data expirare CI'
                                 rules={{required: {value: true, message: 'Data expirare CI este obligatorie'}}}
                                 errors={errors} variant='outlined'/>

        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>

          <SelectFormControl control={control} name='employment_type' displayText='Program'
                             selectOptions={[{label: 'Full-time', value: 'full time'}, {
                               label: 'Part-time',
                               value: 'part time'
                             }]} variant='outlined'/>

          <BasicFormControl control={control} name='working_hours' displayText='Numar de ore'
                            type='number' variant='outlined'/>

          <BasicFormControl control={control} name='profession' displayText='Profesie' variant='outlined'/>

        </Box>
      </Box>
      <Button sx={{my: 2, width: '100%'}} type="submit" variant="contained">Submit</Button>
    </form>
  )
}

export default CreateEmployeeForm;