import {Container, Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme} from "@mui/material";
import Table from "../../components/table/Table";
import {AutoFleetTableConstants} from "./AutoFleetTableConstants";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Close} from "@mui/icons-material";
import CreateAutoFleetForm from "../../components/forms/CreateAutoFleetForm";
import {FieldValues} from "react-hook-form";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import DeleteConfirmationDialog from "../../components/DeleteConfirmationDialog";
import {KeyValuePair} from "../../types/CommonTypes";
import {FilterSettings} from "../../types/FilterTypes";
import Filters from "../../components/filters/Filters";

const AutoFleetPage = () => {
  const [autoFleets, setAutoFleets] = useState<any>([]);
  const [createAutoFleetDialogOpen, setCreateAutoFleetDialogOpen] = useState<boolean>(false);
  const [rowDeleteRequested, setRowDeleteRequested] = useState<any>(undefined);
  const [reset, setReset] = useState<number>(0);
  const [queryParams, setQueryParams] = useState<string>('');
  const [filters, setFilters] = useState<KeyValuePair>({} as KeyValuePair);

  const actions: any[] = [
    {
      name: 'deleteInvoiceRow',
      displayName: 'Delete row',
      action: handleDeleteRowRequest
    }
  ]

  const navigate = useNavigate();

  const filterable: FilterSettings[] = [
    {
      key: 'model',
      display: 'Denumire vehicul',
      type:'text'
    },
    {
      key: 'reg_no',
      display:'Numar de inmatriculare',
      type: 'text'
    }
  ];

  const location = useLocation();

  useEffect(() => {
    if (queryParams !== location.search) {
      setQueryParams(location.search);
    }
  }, [location]);

  useEffect(() => {
    api.get(`/autofleet${queryParams}`).then((res) => {
      setAutoFleets(res);
    }).catch((err) => {
      toast.error(err);
    })
  }, [queryParams, reset])

  useEffect(() => {
    if (filters) {
      navigate(`?${new URLSearchParams(filters).toString()}`);
    }
  }, [filters])

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  function handleDeleteRowRequest(row: any) {
    setRowDeleteRequested(row);
  }

  const handleDeleteRow = () => {
    api.remove(`/autofleet/${rowDeleteRequested.auto_fleet_id}`).then((res) => {
      toast.success(res.message);
      setRowDeleteRequested(undefined);
      setReset(reset + 1);
    }).catch((err) => {
      toast.error(err);
    })
  }

  const rowDeleteCanceled = (row: any) => {
    setRowDeleteRequested(null);
  }

  const formatCell = (row: any, value: string) => {
    return <Link to={`${row.auto_fleet_id}`}>{value}</Link>
  }

  const getCellColor = (rowKey: string, index: number) => {
    const currentDate = new Date().getTime();
    const comparisonDate = new Date(autoFleets[index][rowKey]).getTime();

    const timeDiff = comparisonDate - currentDate;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (daysDiff > 7 && daysDiff <= 30) {
      return 'orange';
    }

    if (daysDiff <= 7) {
      return 'red';
    }

    return "";
  }

  const handleAutoFleetCreation = (data: FieldValues) => {
    api.post('/autofleet', data).then((res) => {
      setCreateAutoFleetDialogOpen(false);
      toast.success('Masina a fost adaugata');
      setReset(reset + 1);
    }).catch((err) => {

      toast.error(err.message);
    });
  }

  const onFilterSubmit = (filters: KeyValuePair) => {
    setFilters(filters);
  }

  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <Filters filters={filters} filtersSettings={filterable} onFiltersSubmit={onFilterSubmit}/>

      <Table headers={AutoFleetTableConstants}
             rows={autoFleets}
             getFormattedCell={formatCell}
             handleAddActionClick={() => setCreateAutoFleetDialogOpen(true)}
             actions={actions}
             getCellColor={getCellColor}
      />

      {rowDeleteRequested &&
          <DeleteConfirmationDialog rowDeleteRequested={rowDeleteRequested}
                                    deleteConfirmationText={rowDeleteRequested.reg_no}
                                    handleDeleteRow={handleDeleteRow} rowDeleteCanceled={rowDeleteCanceled}/>
      }

      <Dialog
        fullScreen={fullScreen}
        open={createAutoFleetDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-2"
                     sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          Adauga masina
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setCreateAutoFleetDialogOpen(false)}
            aria-label="close"
            sx={{justifySelf: 'end'}}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{height: '100%'}}>
          <CreateAutoFleetForm onAutoFleetSubmit={handleAutoFleetCreation}/>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default AutoFleetPage;