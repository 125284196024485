import {Container, CssBaseline, ThemeProvider} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import {Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Header from './components/Layout';
import useAuth from './context/AuthContext';
import HomePage from './pages/home/HomePage';
import LoginPage from './pages/login/LoginPage';
import 'react-toastify/dist/ReactToastify.css';
import {ReactNode, useEffect, useState} from "react";
import ProtectedRoute from "./router/ProtectedRoute";
import PartnerPage from "./pages/partners/PartnersPage";
import CreatePartnerPage from "./pages/partners/CreatePartnerPage";
import PartnerDetailsPage from "./pages/partners/PartnerDetailsPage";
import ProductsPage from "./pages/products/ProductsPage";
import ProductDetailsPage from "./pages/products/ProductDetailsPage";
import CreateProductPage from "./pages/products/CreateProductPage";
import InvoiceDetailsPage from "./pages/invoices/InvoiceDetailsPage";
import EmployeesPage from "./pages/employee/EmployeesPage";
import EmployeeDetailsPage from "./pages/employee/EmployeeDetailsPage";
import CreateEmployeePage from "./pages/employee/CreateEmployeePage";
import UserSettingsPage from "./pages/user/UserSettingsPage";
import TimesheetDetailsPage from "./pages/timesheet/TimesheetDetailsPage";
import IssuedInvoicesPage from "./pages/invoices/IssuedInvoicesPage";
import NoticesPage from "./pages/invoices/NoticesPage";
import ReceivedInvoicesPage from "./pages/invoices/ReceivedInvoicesPage";
import CreateTimesheetEntriesPage from './pages/timesheet/CreateTimesheetEntriesPage';
import AutoFleetPage from "./pages/auto-fleet/AutoFleetPage";
import AutoFleetDetailsPage from "./pages/auto-fleet/AutoFleetDetailsPage";
import BankRegisterPage from "./pages/bank-register/BankRegisterPage";
import CashRegisterPage from "./pages/cash-register/CashRegisterPage";
import ReceiptPage from "./pages/receipt/ReceiptPage";
import CashRegisterDetailsPage from "./pages/cash-register/CashRegisterDetailsPage";
import BankRegisterDetailsPage from "./pages/bank-register/BankRegisterDetailsPage";
import ReceiptDetailsPage from "./pages/receipt/ReceiptDetailsPage";
import OrdersPage from "./pages/orders/OrdersPage";
import OrderDetailsPage from "./pages/orders/OrderDetailsPage";
import {Font} from "@react-pdf/renderer";
// @ts-ignore
import Roboto from "./assets/fonts/Roboto-Regular.ttf"
// @ts-ignore
import RobotoBlack from "./assets/fonts/Roboto-Black.ttf";
// @ts-ignore
import RobotoBold from "./assets/fonts/Roboto-Bold.ttf";
import NoticeDetailsPage from "./pages/invoices/NoticeDetailsPage";
import UsersPage from "./pages/users/UsersPage";
import UsersDetailsPage from "./pages/users/UsersDetailsPage";
import {Roles} from "./common/enums/roles";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

Font.register({
  family: 'Roboto',
  src: Roboto
});

Font.register({
  family: 'Roboto-Black',
  src: RobotoBlack
});

Font.register({
  family: 'Roboto-Bold',
  src: RobotoBold
})

function App() {
  const auth = useAuth();
  const [authState, setAuthState] = useState<string | null | undefined>(null);

  useEffect(() => {
    setAuthState(auth.token);
  }, [auth.token]);

  // define theme
  const theme = createTheme({
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

  const loginContainer = (): ReactNode => (
    <Routes>
      <Route key="login" path="/login" element={<LoginPage/>}/>
    </Routes>
  );

  const defaultContainer = (): ReactNode => (
    <>
      <Header>
        <Routes>
          <Route path='/' element={
            <ProtectedRoute roles={[]}>
              <HomePage/>
            </ProtectedRoute>}/>
          <Route path='/partners/:id' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <PartnerDetailsPage/>
            </ProtectedRoute>
          }></Route>
          <Route path='/partners' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <PartnerPage/>
            </ProtectedRoute>
          }/>
          <Route path='/partners/create' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <CreatePartnerPage/>
            </ProtectedRoute>
          }/>
          <Route path='/products' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <ProductsPage/>
            </ProtectedRoute>
          }/>
          <Route path='/products/:id' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <ProductDetailsPage/>
            </ProtectedRoute>
          }></Route>
          <Route path='/products/create' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <CreateProductPage/>
            </ProtectedRoute>
          }></Route>
          <Route path='/invoices/:id' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <InvoiceDetailsPage/>
            </ProtectedRoute>
          }></Route>
          <Route path='/invoices/issued' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <IssuedInvoicesPage/>
            </ProtectedRoute>
          }/>
          <Route path='/invoices/notice' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator, Roles.Sofer]}>
              <NoticesPage/>
            </ProtectedRoute>
          }/>
          <Route path='/notices/:id' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator, Roles.Sofer]}>
              <NoticeDetailsPage/>
            </ProtectedRoute>
          }/>
          <Route path='/invoices/received' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <ReceivedInvoicesPage/>
            </ProtectedRoute>
          }/>
          <Route path='/hr/employees' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <EmployeesPage/>
            </ProtectedRoute>
          }/>
          <Route path='/hr/employees/:id' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <EmployeeDetailsPage/>
            </ProtectedRoute>
          }/>
          <Route path='/employees/create' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <CreateEmployeePage/>
            </ProtectedRoute>
          }/>
          <Route path='/hr/timesheet' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <TimesheetDetailsPage/>
            </ProtectedRoute>
          }/>
          <Route
            path="/timesheet/add" element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <CreateTimesheetEntriesPage/>
            </ProtectedRoute>
          }
          />
          <Route path='/user/settings' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator, Roles.Sofer]}>
              <UserSettingsPage/>
            </ProtectedRoute>
          }/>
          <Route path='/autofleet' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <AutoFleetPage/>
            </ProtectedRoute>
          }/>
          <Route path='/autofleet/:id' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <AutoFleetDetailsPage/>
            </ProtectedRoute>
          }/>
          <Route path='/bankregister' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <BankRegisterPage/>
            </ProtectedRoute>
          }/>
          <Route path='/bankregister/:id' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <BankRegisterDetailsPage/>
            </ProtectedRoute>
          }/>
          <Route path='/cashregister' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <CashRegisterPage/>
            </ProtectedRoute>
          }/>
          <Route path='/cashregister/:id' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <CashRegisterDetailsPage/>
            </ProtectedRoute>
          }/>
          <Route path='/orders' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <OrdersPage/>
            </ProtectedRoute>
          }/>
          <Route path='/orders/:id' element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <OrderDetailsPage/>
            </ProtectedRoute>
          }/>
          <Route path="/receipts" element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <ReceiptPage/>
            </ProtectedRoute>
          }/>
          <Route path="/receipts/:id" element={
            <ProtectedRoute roles={[Roles.Administrator, Roles.Operator]}>
              <ReceiptDetailsPage/>
            </ProtectedRoute>
          }/>
          <Route path='/users' element={
            <ProtectedRoute roles={[Roles.Administrator]}>
              <UsersPage/>
            </ProtectedRoute>
          }/>
          <Route path='/users/:id' element={
            <ProtectedRoute roles={[Roles.Administrator]}>
              <UsersDetailsPage/>
            </ProtectedRoute>
          }/>
          <Route path='*' element={
            <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
              <h2>404</h2>
            </Container>
          }/>
        </Routes>
      </Header>
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <ToastContainer/>

      {authState ? defaultContainer() : loginContainer()}
    </ThemeProvider>
  );
}

export default App;
