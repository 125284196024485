export const InvoiceTableHeaders = [
  {
    id: ['series', 'number'],
    label: 'Numar',
    width: 100,
    type: 'href',
    composite: true
  },
  {
    id: "buyer",
    label: "Beneficiar",
    width: 100,
  },
  {
    id: "created_at_utc",
    label: "Data emitere",
    width: 100,
    type: 'date'
  },
  {
    id: "deadline_at_utc",
    label: "Data scadenta",
    width: 100,
    type: 'date'
  },
  {
    id: "status",
    label: "Status",
    width: 100,
    type: 'colored-cell'
  },
  {
    id: "total_price_incl_vat",
    label: "Pret Total (incl. TVA)",
    width: 100,
  },
  {
    id: "total_paid_price",
    label: "Pret total platit",
    width: 100
  },
  {
    id: 'order_reference_number',
    label: 'Referinta comanda',
    width: 50,
    type: 'href'
  }
]