import React, {useEffect, useState} from 'react';

import * as api from "../../api/api-client";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SelectFormControl from '../form-helpers/SelectFormControl';
import {FieldValues, useForm} from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {Close} from '@mui/icons-material';

import {toast} from 'react-toastify';

import CreateTimesheetEntryForm from '../forms/CreateTimesheetEntryForm';
import {EmployeeTimesheet, TimesheetEntry} from '../../types/TimesheetDto';
import {
  calculateHolidaysCount,
  calculateIllDaysCount,
  calculateSupplementaryHours,
  calculateTotalHours,
  getDaysInMonth,
  isWeekend,
  months,
  years
} from '../../utils/timesheetHelpers';
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";

const defaultFilter = {
  label: "TOTI PARTENERII",
  value: "0",
}

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [addActionDialogOpen, setAddActionDialogOpen] = useState(false);

  const [employees, setEmployees] = useState<EmployeeTimesheet[]>([]);
  const [addresses, setAddresses] = useState<any>([]);

  const [currentAddress,setCurrentAddress] = useState<any>({});
  const [selectedTimesheetEntry, setSelectedTimesheetEntry] = useState<TimesheetEntry>({} as TimesheetEntry);
  const [editedTimesheetEntries, setEditedTimesheetEntries] = useState<TimesheetEntry[]>([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      month: new Date().getMonth().toString(),
      year: new Date().getFullYear().toString(),
      address_id: "0",
    }
  });

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === "year" || name === "month") setCurrentDate(new Date(parseInt(value.year || "2023"), parseInt(value.month || "")));

    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const handleDialogOpen = (employee_id: number, timesheet_entry_id: number | undefined, hours_worked: string | undefined, date: Date) => () => {
    setSelectedTimesheetEntry({
      timesheet_entry_id,
      employee_id,
      hours_worked: hours_worked || "",
      date,
      address_id:1,
    });
    setAddActionDialogOpen(true);
  }

  const handleTimesheetEntrySubmit = (data: FieldValues) => {
    const existingTimesheetEntry = editedTimesheetEntries.find(x => x.date.toDateString() === (data.date as Date).toDateString());

    if (existingTimesheetEntry) {
      setEditedTimesheetEntries(editedTimesheetEntries.map(t => t.timesheet_entry_id === existingTimesheetEntry.timesheet_entry_id ? data as TimesheetEntry : t));
    } else {
      setEditedTimesheetEntries([...editedTimesheetEntries, data as TimesheetEntry]);
    }

    setAddActionDialogOpen(false);
  }

  const handleTimesheetUpdate = () => {
    api
      .post("/timesheet/employee", editedTimesheetEntries)
      .then((res) => {
        toast("Tabelul a fost actualizat cu succes.", {position: "top-right", type: "success"})
      })
      .catch((err: any) => {
        toast(err.message, {position: 'bottom-right', type: 'error'})
      });
  }

  const onAddressAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/address/autocomplete?searchKey=${value}`).then((res) => {
        setAddresses(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }

  const onAddressAutocompleteChange = (event: any, value: any) => {
    if(!value){
      return;
    }
    setValue('address_id', value.address_id);
    api.get(`/timesheet/employee/${value.address_id}`).then((res: EmployeeTimesheet[]) => {
        const employeesResponse = res.map(employee => ({
          ...employee,
          timesheetEntries: employee.TimesheetEntries.map((timesheet: any) => ({
            ...timesheet,
            date: new Date(timesheet.date),
          }))
        }));
        setEmployees(employeesResponse);
        setCurrentAddress(value);
      }).catch((err: any) => {
        toast(err, {position: 'bottom-right', type: 'error'})
      });
  }

  const getFullTimesheet = (employee: EmployeeTimesheet) => {
    let fullTimesheet = [...editedTimesheetEntries.filter(t => t.employee_id === employee.employee_id)];

    employee.TimesheetEntries.forEach((timesheetEntry: TimesheetEntry) => {
      if (!fullTimesheet.find((entry: TimesheetEntry) => new Date(entry?.date).toDateString() === new Date(timesheetEntry?.date).toDateString())) {
        fullTimesheet.push(timesheetEntry);
      }
    });

    return fullTimesheet;
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={addActionDialogOpen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-title"
                     sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          Adauga pontaj
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setAddActionDialogOpen(false)}
            aria-label="close"
            sx={{justifySelf: 'end'}}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{height: '100%'}}>
          <CreateTimesheetEntryForm employees={employees} timesheetEntry={selectedTimesheetEntry}
                                    onTimesheetSubmit={handleTimesheetEntrySubmit} address={currentAddress.address_id}/>
        </DialogContent>
      </Dialog>

      <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>

        <Toolbar sx={{width: '100%', my: '1rem', padding: '0px !important'}}>
          <Accordion sx={{width: '100%'}} expanded={true}>
            <AccordionSummary>
              Setari pontaj
            </AccordionSummary>
            <AccordionDetails sx={{m: 0, display: 'flex', flexDirection: 'column'}}>

              <Box sx={{m: 0, display: 'flex', flexDirection: {md: 'row', xs: 'column'},pb:3}}>
                <Box sx={{display: 'flex', width: '100%',my:2}}>
                  <SelectFormControl control={control} name={"month"} selectOptions={months}/>
                </Box>

                <Box sx={{display: 'flex', width: '100%',my:2}}>
                  <SelectFormControl control={control} name={"year"} selectOptions={years}/>
                </Box>

                  <AutocompleteFormControl
                    control={control}
                    name="address_id"
                    displayText="Adresa"
                    variant="outlined"
                    onInputHandler={onAddressAutocompleteInput}
                    onChangeHandler={onAddressAutocompleteChange}
                    optionLabelFunc={(addresses) => addresses?.address_id ? (addresses?.nickname || "") : ""}
                    options={addresses}
                  />
                
              </Box>

              <Button variant="contained" onClick={handleTimesheetUpdate} disabled={!employees.length}>actualizeaza pontaje</Button>
            </AccordionDetails>
          </Accordion>
        </Toolbar>

        {employees.length ? 
        (
        <TableContainer sx={{maxHeight: "100%"}}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Angajat</TableCell>
                <TableCell sx={{borderLeft: "0.5px solid gainsboro"}} colSpan={2}>Profesie</TableCell>
                <TableCell sx={{borderLeft: "0.5px solid gainsboro"}} colSpan={2}>Santier</TableCell>
                {getDaysInMonth(currentDate.getMonth(), currentDate.getFullYear()).map((column: Date) => (
                  <TableCell
                    sx={{
                      borderLeft: "0.5px solid gainsboro",
                      backgroundColor: isWeekend(column) ? "silver" : "",
                      color: isWeekend(column) ? "red" : ""
                    }}
                    align="center"
                    key={column.getDate()}
                  >
                    {column.getDate()}
                  </TableCell>
                ))}

                <TableCell sx={{borderLeft: "0.5px solid gainsboro", backgroundColor: "silver"}}
                           align="center">T</TableCell>
                <TableCell sx={{borderLeft: "0.5px solid gainsboro", backgroundColor: "silver"}}
                           align="center">S</TableCell>
                <TableCell sx={{borderLeft: "0.5px solid gainsboro", backgroundColor: "silver"}}
                           align="center">BO</TableCell>
                <TableCell sx={{borderLeft: "0.5px solid gainsboro", backgroundColor: "silver"}}
                           align="center">CO</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees
                ?.map((employee: EmployeeTimesheet) => {
                  const fullTimesheet = getFullTimesheet(employee);

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={employee.employee_id}>
                      <TableCell colSpan={2}>{employee.first_name + " " + employee.last_name}</TableCell>
                      <TableCell sx={{borderLeft: "0.5px solid gainsboro"}}
                                 colSpan={2}>{employee.profession}</TableCell>
                      <TableCell sx={{borderLeft: "0.5px solid gainsboro"}} colSpan={2}>
                        {currentAddress?.nickname}
                      </TableCell>
                      {getDaysInMonth(currentDate.getMonth(), currentDate.getFullYear()).map((date: Date) => {
                        const timesheetEntry =
                          editedTimesheetEntries.find(d => new Date(d?.date).toDateString() === new Date(date).toDateString() && d.employee_id === employee.employee_id) ||
                          employee.TimesheetEntries.find(e => new Date(e.date).toDateString() === new Date(date).toDateString());

                        return (
                          <TableCell
                            sx={{
                              borderLeft: "0.5px solid gainsboro",
                              backgroundColor: isWeekend(date) ? "silver" : ""
                            }}
                            align="center"
                            key={date.getDate()}
                            onClick={handleDialogOpen(employee.employee_id, timesheetEntry?.timesheet_entry_id, timesheetEntry?.hours_worked, date)}
                          >
                            {timesheetEntry?.hours_worked}
                          </TableCell>
                        );
                      })}

                      <TableCell sx={{borderLeft: "0.5px solid gainsboro", backgroundColor: "silver"}}
                                 align="center">
                        {calculateTotalHours(fullTimesheet, currentDate)}
                      </TableCell>
                      <TableCell sx={{borderLeft: "0.5px solid gainsboro", backgroundColor: "silver"}}
                                 align="center">
                        {calculateSupplementaryHours(employee, fullTimesheet, currentDate)}
                      </TableCell>
                      <TableCell sx={{borderLeft: "0.5px solid gainsboro", backgroundColor: "silver"}}
                                 align="center">
                        {calculateIllDaysCount(fullTimesheet, currentDate)}
                      </TableCell>
                      <TableCell sx={{borderLeft: "0.5px solid gainsboro", backgroundColor: "silver"}}
                                 align="center">
                        {calculateHolidaysCount(fullTimesheet, currentDate)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        ) 
        :
        (
          <Typography align='center'>Alegeti o adresa pentru a vizualiza angajatii</Typography>
        )
        }
        
      </Container>
    </>
  )
    ;
}

export default Calendar;