import {Autocomplete, Box, TextField, Typography} from "@mui/material";
import {Controller, FieldErrors, FieldPath, FieldValues} from "react-hook-form";
import {RegisterOptions} from "react-hook-form/dist/types/validator";

interface Props {
  control: any;
  name: string;
  displayText: string;
  rules?: Omit<RegisterOptions<FieldValues, FieldPath<any>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  errors?: FieldErrors;
  type?: string;
  onInputHandler?: (event: any) => void;
  onChangeHandler?: (event: any, value: any) => void;
  onBlurHandler?: (event: any) => void;
  options: any;
  optionLabelFunc: (values: any) => string;
  variant?: 'outlined' | 'standard';
  disabled?: boolean;
  defaultValue?: any;
}

const AutocompleteFormControl = (props: Props) => {
  return (
    <Box sx={{width: '100%', mx: {xs: '0', md: '1rem'}}}>
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        render={(field) => (
          <Autocomplete
            {...field}
            options={props.options}
            onInput={props.onInputHandler}
            onChange={props.onChangeHandler}
            onBlur={props?.onBlurHandler}
            getOptionLabel={props.optionLabelFunc}
            defaultValue={props.defaultValue || ''}
            sx={{width: '100%'}}
            renderInput={(params) => (
              <TextField
                {...params}
                name={props.name}
                sx={{width: '100%', my: '1rem'}}
                variant={props.variant || 'standard'}
                label={props.displayText}
                error={!!props.errors?.[props.name]}
                disabled={props.disabled || false}
              />
            )}
          />
        )}
      />
      {
        props.errors?.[props.name] &&
          <Typography variant='caption'
                      sx={{color: 'red'}}>{props.errors?.[props.name]?.message?.toString()}</Typography>
      }
    </Box>
  )
}

export default AutocompleteFormControl;